<template>
	<div>
		<el-tabs v-model="listState" @tab-click="goodsSearch" style="background: #fff;padding-left: 15px;">
			<el-tab-pane v-for="item in listStateList" :key="item.id" :label="item.value" :name="item.id+''"></el-tab-pane>
		</el-tabs>

		<div class="filter-container" style="background: #fff;padding: 15px;">
			<!-- 销售单 -->
			<div class="filter-item" v-if="listState==0">
				<label class="label">关键字: </label>
				<el-select v-model="KeyWordType" style="width: 160px;margin-right: 10px;">
					<el-option v-for="item in changeTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-input clearable style="width:250px" :placeholder="changeTypeValue[KeyWordType]" v-model="KeyWords"></el-input>
			</div>

			<!-- 退款单 -->
			<div class="filter-item" v-if="listState==1">
				<label class="label">关键字: </label>
				<el-select v-model="KeyWordType1" style="width: 160px;margin-right: 10px;">
					<el-option v-for="item in changeTypeList1" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				 <!-- @clear="clearKeywords1" -->
				<el-input clearable style="width:250px" :placeholder="changeTypeValue1[KeyWordType1]" v-model="KeyWords1"></el-input>
			</div>

			<div class="filter-item">
				<label class="label">业绩类型: </label>
				<el-select v-model="PerformanceType" style="margin-right: 10px;">
					<el-option label="支付业绩" :value="2"></el-option>
					<el-option label="结算业绩" :value="1"></el-option>
				</el-select>
			</div>

			<div class="filter-item">
				<label class="label">时间筛选: </label>
				<!-- 活动时间 -->
				<el-date-picker format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" v-model="StartTime" type="datetime"
				 placeholder="开始时间" :picker-options='maxOptions'>
				</el-date-picker>
				<span style="margin:0 10px;">~</span>
				<el-date-picker format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" v-model="EndTime" type="datetime"
				 placeholder="结束时间" :picker-options='minOptions'>
				</el-date-picker>
			</div>

			<div class="filter-item">
				<label class="label">门店标签: </label>
				<el-select v-model="ShopTagId" style="margin-right: 10px;">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in tagList" :key="item.Id" :label="item.TagName" :value="item.Id">
					</el-option>
				</el-select>
			</div>
			<div class="filter-item">
				<label class="label">归属门店: </label>
				<el-select filterable clearable v-model="ShopId" style="margin-right: 10px;">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in dropList" :key="item.Id" :label="item.ShopName" :value="item.Id">
					</el-option>
				</el-select>
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="goodsSearch">查询</el-button>
				<el-button type="" style="margin-left:30px;width:90px;" @click="handleExport">导出</el-button>

			</div>
		</div>
		<div class="table-container" style="background: #fff;padding: 15px;" v-if="listState==0">
			<el-table :data="groupData" style="width: 100%;" v-loading="loading">
				<el-table-column prop="AddTimeStr" label="员工">
					<template slot-scope="scope">
						<div class="note-detail">
							{{scope.row.EmployeeName}}
							<br>
							{{scope.row.Phone}}
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="ShopName" label="归属门店"></el-table-column>
				<el-table-column label="订单">
					<template slot-scope="scope">
						<div class="note-detail">
							<span style="color:rgb(245, 108, 108)">[{{scope.row.SendTypeName}}]</span>
							<br>
							<span style="color:#409EFF;cursor: pointer;" @click='pushTorderdetail(scope.row)'>{{scope.row.OrderNo}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="OrderStateName" label="订单状态"></el-table-column>
				<el-table-column prop="PayMoney" label="支付金额"></el-table-column>
				<el-table-column prop="AwardMoney" label="提成"></el-table-column>
				<el-table-column :label="PerformanceValue == 2 ?'支付时间':'发货时间'">
					<template slot-scope="scope">
						<span v-if='PerformanceValue == 2'>{{scope.row.PayTime}}</span>
						<span v-else>{{scope.row.SendTime}}</span>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="PayTime" label="支付时间"></el-table-column> -->
			</el-table>
			<div style="display:flex;justify-content:space-between;align-items:center;">
				<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;">
				</div>
				<el-pagination v-if="Total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				 :total="Total">
				</el-pagination>
			</div>
		</div>

		<div class="table-container" style="background: #fff;padding: 15px;" v-if="listState==1">
			<el-table :data="groupData" style="width: 100%;" v-loading="loading">
				<el-table-column prop="AddTimeStr" label="员工">
					<template slot-scope="scope">
						<div class="note-detail">
							{{scope.row.EmployeeName}}
							<br>
							{{scope.row.Phone}}
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="ShopName" label="归属门店"></el-table-column>
				<el-table-column label="退款单">
					<template slot-scope="scope">
						<div class="note-detail">
							<span style="color:rgb(245, 108, 108)">[{{scope.row.SendTypeName}}]</span>
							<br>
							<span style="color:#409EFF;cursor: pointer;" @click='pushTorefundDetail(scope.row)'>{{scope.row.SupportNo}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="RefundMoney" label="退款金额"></el-table-column>
				<el-table-column prop="CompleteTime" label="退款时间"></el-table-column>
			</el-table>
			<div style="display:flex;justify-content:space-between;align-items:center;">
				<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;">
				</div>
				<el-pagination v-if="Total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				 :total="Total">
				</el-pagination>
			</div>
		</div>


	</div>
</template>

<script>
	import config from '@/config/index'
	import apiList from '@/api/other'
	import {
		shoptaglist,
		shopdroplist
	} from '@/api/api'
	import {
		employeSupportList
	} from '@/api/goods'
	export default {
		data() {
			return {
				listState: '',
				listStateList: [{
						id: 0,
						value: '销售单'
					},
					{
						id: 1,
						value: '退款单'
					}
				],
				ShopTagId: null,
				StartTime: '',
				EndTime: '',
				PerformanceType: 2,
				PerformanceValue: 2,
				tagList: [],
				ShopId: null,
				dropList: [],
				KeyWords1:'',
				KeyWords: '',
				KeyWordType: 0,
				KeyWordType1: 0,
				changeTypeList: [{
						value: 0,
						label: '订单编号'
					},
					{
						value: 1,
						label: '员工'
					}
				],
				changeTypeList1: [{
						value: 0,
						label: '退款单号'
					},
					{
						value: 1,
						label: '员工'
					}
				],
				changeTypeValue: ['订单编号', '员工姓名、手机号'],
				changeTypeValue1: ['退款单号', '员工姓名、手机号'],
				TimeValue: [],
				groupData: [],
				currentPage: 1,
				pageSize: 20,
				Total: 1,
				searchKey: '',
				loading: false,

				exportUrl: config.EXPORT_URL,
				goUrls: config.GO_URL,
			}
		},
		computed: {
			//日期选择器限制选择
			minOptions: function() {
				let limitTime = this.StartTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return time < new Date(new Date(limitTime).toLocaleDateString())
						}
					},
				}
			},
			//日期选择器限制选择
			maxOptions: function() {
				let limitTime = this.EndTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return time > new Date(new Date(limitTime).toLocaleDateString())
						}
					}
				}
			}
		},
		beforeMount() {
			if (this.$route.query.employPhone) {
				this.KeyWords = this.$route.query.employPhone
				this.KeyWordType = 1
				this.KeyWords1 = this.$route.query.employPhone
				this.KeyWordType1 = 1
			}
			this.PerformanceType = Number(this.$route.query.PerformanceType)||2
			this.PerformanceValue= this.PerformanceType 
			// console.log("this.PerformanceType:",this.PerformanceType,"路由参数:",this.$route.query.PerformanceType)
			console.log(this.$route.query.isRefuse)
			this.listState = this.$route.query.isRefuse=='true' ? '1' : '0'
			this.getShoptaglist()
			this.getShopdroplist()
			this.getList()
		},
		created() {},
		methods: {
			clearKeywords1(){
				console.log('没有课上得好是')
				this.KeyWords1 = ''
			},
			//跳转到退款单详情
			pushTorefundDetail(record) {
				let OrderNoId = record.SupportNo ? record.SupportNo : 0
				let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
				let url = this.goUrls + headsUrls + '/order/refundAfterSale/refundDetails?id=' + OrderNoId
				window.open(url)
			},
			//跳转到订单详情
			pushTorderdetail(record) {
				let OrderNoId = record.OrderNo ? record.OrderNo : 0
				let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
				let url = this.goUrls + headsUrls + '/order/orderDetailpage?Id=' + OrderNoId

				window.open(url)
			},
			async getShopdroplist() {
				try {
					let doorResult = await shopdroplist({
						IsOpenRoleJurisdiction: true
					})
					this.dropList = this.dropList.concat(doorResult.Result)

				} catch (e) {
					//TODO handle the exception
				}
			},

			async getShoptaglist() {
				try {
					let doorResult = await shoptaglist({})
					this.tagList = doorResult.Result

				} catch (e) {
					//TODO handle the exception
				}
			},
			// 
			// 
			//获取数据
			async getList() {
				this.loading = true
				try {
					let result = {}
					let data = {
						KeyWordType: this.listState == 0 ? this.KeyWordType : this.KeyWordType1,
						KeyWords: this.listState == 0 ? this.KeyWords : this.KeyWords1,
						ShopTagId: this.ShopTagId,
						ShopId: this.ShopId,
						StartTime: this.StartTime ? this.StartTime : '',
						PerformanceType: this.PerformanceType,
						EndTime: this.EndTime ? this.EndTime : '',
						Skip: (this.currentPage - 1) * this.pageSize,
						Take: this.pageSize,
					}
					this.PerformanceValue = this.PerformanceType
					if (this.listState == 0) {
						result = await apiList.performanceOrderList(data)
					} else {
						result = await employeSupportList(data)

					}
					this.Total = result.Result.Total;
					this.groupData = result.Result.Results || [];
				} catch (e) {
					console.log('e', e)
				} finally {
					this.loading = false
				}
			},
			goodsSearch() {
				this.currentPage = 1
				this.getList();
			},
			async handleExport() {
				this.loading = true
				try {
					let url = ""
					if (this.listState == 0) {
						url = this.exportUrl + '/pc/employeePerformance/orderExport?' +
							'&KeyWordType=' + (this.KeyWordType ? this.KeyWordType : '') +
							'&KeyWords=' + (this.KeyWords ? this.KeyWords : '') +
							'&ShopTagId=' + (this.ShopTagId ? this.ShopTagId : '') +
							'&ShopId=' + (this.ShopId ? this.ShopId : '') +
							'&StartTime=' + (this.StartTime ? this.StartTime : '') +
							'&EndTime=' + (this.EndTime ? this.EndTime : '') + 
							'&PerformanceType=' + this.PerformanceType
					} else {
						url = this.exportUrl + '/pc/employeePerformance/supportExport?' +
							'&KeyWordType=' + (this.KeyWordType1 ? this.KeyWordType1 : '') +
							'&KeyWords=' + (this.KeyWords1 ? this.KeyWords1 : '') +
							'&ShopTagId=' + (this.ShopTagId ? this.ShopTagId : '') +
							'&ShopId=' + (this.ShopId ? this.ShopId : '') +
							'&StartTime=' + (this.StartTime ? this.StartTime : '') +
							'&EndTime=' + (this.EndTime ? this.EndTime : '') + 
							'&PerformanceType=' + this.PerformanceType
					}

					window.open(url);
				} catch (error) {
					console.log(error);
				} finally {
					this.loading = false
				}
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.pageSize = val;
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
		}
	}
</script>

<style lang="less" scoped>
	.filter-top {
		background: #fff;
		padding: 0 15px;
		width: 100%;
		margin-bottom: 10px;
	}

	.note-detail {
		width: 350px;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		/* autoprefixer: ignore next */
		-webkit-box-orient: vertical;
	}
</style>

<style lang="less" scoped>
</style>
